import React from "react";
import '../../assets/css/common/footer.css';

export default function Footer() {
    return (
        <footer className="footer">
            <p>사람과 사람을 잇다, ItDat</p>
        </footer>
    );
}
